import { createSignal, Show, Suspense, useContext } from 'solid-js';
import { createAsync } from '@solidjs/router';

import { TaskViewModeType } from 'shared/network/schema';

import { ProjectContext } from 'f/project/project.context';
import { fetchViewModes } from '#/view-modes/network';

import { t } from 'locales/filters';

import { Loader } from 'shared/ui/loader.ui';
import List from 'shared/ui/list';
import { InitialsAvatar } from 'shared/ui/initials-avatar';

import Plus from 'icons/24/Plus Outlined.svg';
import GroupOutlined from 'icons/16/Group Outlined.svg';
import { ListViewMode } from './model';


export default function ViewModeManager() {
  const [project] = useContext(ProjectContext);

  const [shouldRefresh, refresh] = createSignal(null, { equals: () => false });

  const projectViewModes = createAsync(() => {
    return fetchViewModes(project.latest.id);
  }, { initialValue: [] });

  return <main class="p-4 min-h-[var(--tg-viewport-height)] flex flex-col justify-between">
    <div class="flex flex-col gap-2">
      <p class="app-text-body-s/regular c-text-tertiary uppercase">
        {t('list-title')}
      </p>
      <Suspense fallback={
        <List refactor each={[t('loading')]}
          title={<CreateNew />}
        >
          {text => <List.Item right={<Loader />}>
            <span class="app-text-body-l/regular c-text-secondary">
              {text}
            </span>
          </List.Item>}
        </List>
      }>
        <List refactor each={projectViewModes.latest}
          title={<CreateNew />}
        >
          {mode => <List.Item
            href={mode.id}
            left={
              <InitialsAvatar small
                user={mode.author}
                fallback={
                  isBuiltinViewMode(mode)
                  && <img src={`${import.meta.env.BASE_URL}${import.meta.env.APP_BASE ?? '/'}Logo.jpg`} />
                }
              />
            }
            rightHint={
              <Show when={mode.type === TaskViewModeType.Public}>
                <GroupOutlined class="ui-icon-tertiary" />
              </Show>
            }
          >
            <span class="app-text-body-l/regular">
              {mode.title}
            </span>
          </List.Item>}
        </List>
      </Suspense>
      <p class="app-text-body-s/regular c-text-tertiary">
        {t('list-hint')}
      </p>
    </div>
  </main>;

  function CreateNew() {
    return <List.Item semantic right={<></>}
      href="new"
      left={<Plus class="ui-icon-accented" />}
    >
      <span class="c-text-accented app-text-body-l/regular">
        {t('create-new')}
      </span>
    </List.Item>;
  }
}

function isBuiltinViewMode(mode: ListViewMode) {
  return mode.author.userId == -1;
}
